import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SignUpRoutingModule } from './sign-up-routing.module';
import { SignUpComponent } from './sign-up.component';
import { RegistrationComponent } from './registration/registration.component';
import { HomeComponent } from './home/home.component';
import { RegistrationSmall2Component } from './registration-small2/registration-small2.component';
import { RegistrationCorp2Component } from './registration-corp2/registration-corp2.component';
import { RegistrationCorp3Component } from './registration-corp3/registration-corp3.component';
import { RegistrationSmall3Component } from './registration-small3/registration-small3.component';
import { RegistrationSmall4Component } from './registration-small4/registration-small4.component';
import { SignUpInputComponent } from './sign-up-input/sign-up-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SharedModule } from '../../shared/shared.module';
import { RegistrationConfirmComponent } from './registration-confirm/registration-confirm.component';
import { MobileStepsComponent } from './registration/mobile-steps/mobile-steps.component';
import { LegalInformationModule } from '../legal-information/legal-information.module';
import { GdprComponent } from './gdpr/gdpr.component';
import { MetricsComponent } from './metrics/metrics.component';

@NgModule({
  declarations: [
    SignUpComponent,
    RegistrationComponent,
    HomeComponent,
    RegistrationSmall2Component,
    RegistrationCorp2Component,
    RegistrationCorp3Component,
    RegistrationSmall3Component,
    RegistrationSmall4Component,
    SignUpInputComponent,
    RequestPasswordResetComponent,
    ChangePasswordComponent,
    RegistrationConfirmComponent,
    MobileStepsComponent,
    GdprComponent,
    MetricsComponent,
  ],
  imports: [
    CommonModule,
    SignUpRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    LegalInformationModule,
  ],
  exports: [SignUpInputComponent],
})
export class SignUpModule {}
