import { Component, OnInit } from '@angular/core';
import { Tab } from 'src/app/shared/cms/multitab-header/multitab-header.component';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent implements OnInit {
  public tabs: Tab[] = [
    { routerLink: 'calculations', tabText: 'Calculations' },
    /*{
      routerLink: 'invoice-static-contents',
      tabText: 'Invoice static contents',
    },
    { routerLink: 'items', tabText: 'Items' },
    { routerLink: 'sap-tax-indicators', tabText: 'SAP Tax indicators' },*/
  ];

  constructor() {}

  ngOnInit(): void {}
}
