import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CMSService,
  FinanceInvoiceList,
  FinanceInvoiceProductItemEditRequest,
  FinanceInvoiceStatusRequest,
  FinanceInvoiceStatusRequestMass,
} from 'piwe-front-swagger-client';

export interface Invoice {
  id: number;
  type: string;
  number: string;
  language: string;
  company: string;
  client: string;
  createDate: string;
  createdBy: string;
  paymentMethod: string;
  approvedBy: string;
  currency: string;
  netPriceWithoutDiscount: number;
  discount: number;
  netPrice: number;
  tax: number;
  gross: number;
  SAPallowed: boolean;
  fiscalization:
    | 'is fiscalised'
    | 'not fiscalised'
    | "doesn't need fiscalization";
  transferredToSAP: string;
  status:
    | 'new'
    | 'checked'
    | 'ready for SAP'
    | 'transfered to SAP'
    | 'not for SAP';
  dueDate: string;
  invoiceDate: number;
  comment: string;
  additionalText: string;
  productItems: [];
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private invoiceUrl = 'http://localhost:3000/finance_invoices';
  private invoiceItemUrl = 'http://localhost:3000/finance_invoices_items';

  constructor(private http: HttpClient, private cmsService: CMSService) {}

  getInvoices(page: number, params: any): any {
    //filters
    let fromDate: string | undefined = params.fromDate as string | undefined;
    if (fromDate?.length == 0) fromDate = undefined;

    let toDate: string | undefined = params.toDate as string | undefined;
    if (toDate?.length == 0) toDate = undefined;

    let invoice_number: string | undefined = params.invoice_number as
      | string
      | undefined;
    if (invoice_number?.length == 0) invoice_number = undefined;

    let company: string | undefined = params.company as string | undefined;
    if (company?.length == 0) company = undefined;

    let invoice_type: string | undefined = params.invoice_type as
      | string
      | undefined;
    if (invoice_type?.length == 0) invoice_type = undefined;

    let payment_method: string | undefined = params.payment_method as
      | string
      | undefined;
    if (payment_method?.length == 0) payment_method = undefined;

    let status: string | undefined = params.status as string | undefined;
    if (status?.length == 0) status = undefined;

    // remove any return
    //return this.http.get<Invoice[]>(this.invoiceUrl);
    return this.cmsService.cmsGetFinanceInvoiceList(
      page - 1,
      50,
      fromDate,
      toDate,
      Number(invoice_number),
      company,
      invoice_type,
      payment_method,
      status
    );
  }

  deleteInvoice(id: number) {
    const body = {
      id: id,
    };
    return this.cmsService.cmsDeleteFinanceInvoice(body);
  }

  cancelInvoice(id: number) {
    const body = {
      id: id,
    };
    return this.cmsService.cmsCancelFinanceInvoice(body);
  }

  fiscalizeInvoice(id: number) {
    const body = {
      id: id,
    };
    return this.cmsService.cmsFiscalizeFinanceInvoice(body);
  }

  postInvoiceStatusChange(id: number, status: string) {
    const body: FinanceInvoiceStatusRequest = {
      status: status as FinanceInvoiceStatusRequest.StatusEnum,
    };
    return this.cmsService.cmsChangeFinanceInvoiceStatus(id, body);
  }

  postInvoiceStatusChangeMass(ids: number[], status: string) {
    const body: FinanceInvoiceStatusRequestMass = {
      ids: ids,
      status: status as FinanceInvoiceStatusRequest.StatusEnum,
    };
    return this.cmsService.cmsChangeFinanceInvoiceStatusMass(0, body);
  }

  getInvoice(id: number) {
    // return this.http.get(this.invoiceItemUrl + '/' + id);

    return this.cmsService.cmsFinanceInvoice(id);
  }
  patchInvoice(id: number, body: Invoice) {
    return this.http.patch(this.invoiceUrl + '/' + id, body);
  }

  editInvoiceLine(id: number, quantity: number, net: number) {
    const body: FinanceInvoiceProductItemEditRequest = {
      quantity: quantity,
      net: net,
    };
    return this.cmsService.cmsEditFinanceInvoiceLine(id, body);
  }
  deleteInvoiceLine(id: number) {
    return this.cmsService.cmsDeleteFinanceInvoiceLine(id);
  }

  getSapJournalEntryCount(id: number) {
    const body = {
      id: id,
    };
    return this.cmsService.cmsGetFinanceInvoiceSapJournalEntryCount(body);
  }

  getSapJournalEntry(id: number) {
    const body = {
      id: id,
    };
    return this.cmsService.cmsGetFinanceInvoiceSapJournalEntry(
      body,
      'response'
    );
  }

  getInvoicePdf(id: number) {
    const body = {
      id: id,
    };
    return this.cmsService.cmsGetFinanceInvoicePDF(body);
  }
}
