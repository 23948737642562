import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  IAgency,
  ICompanyData,
  ICompanyDownloadRights,
  IUser,
  Serialization,
} from '../../../models';
import { AgencySelectionService } from '../../../desk-administration/agencies/agency-selection/agency-selection.service';
import { CMSService, CMSUsersService } from 'piwe-front-swagger-client';
import { map } from 'rxjs/operators';
import { CmsEditingUser } from '../../../../../../dist/piwe-front-swagger-client/model/cmsEditingUser';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  constructor(
    private cmsService: CMSService,
    private agencySelectionService: AgencySelectionService,
    private userService: CMSUsersService
  ) {}

  getCompanyData(id: number | string): Observable<ICompanyData[]> {
    return of();
  }

  patchCompanyData(id: number | string, data: ICompanyData) {
    return of();
  }

  getUsers(queryParams: any, companyId: number) {
    return of();
  }

  getUser(id: number | string): Observable<CmsEditingUser> {
    return this.userService.cmsGetUser2(id as number);
  }

  patchUser(id: number | string, data: CmsEditingUser) {
    return this.userService.cmsPutUser2(id as number, data);
  }

  createUser(id: number | string, data: CmsEditingUser) {
    return this.userService.cmsPutUser2(id as number, data);
  }

  deleteUser(id: number | string) {
    return of();
  }

  getDownloadRights(
    companyId: number | string
  ): Observable<ICompanyDownloadRights[]> {
    return of();
  }

  patchDownloadRights(id: number | string, data: any) {
    return of();
  }

  getAgencies(): Observable<IAgency[]> {
    return of();
  }
}
