import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CMSService,
  FinanceCalculationItem,
  InvoiceStaticContentItem,
} from 'piwe-front-swagger-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Calculation {
  id: number;
  dateCreated: string;
  createdBy: string;
  forUser: string;
  dateRange: string;
  comment: string;
}

export interface InvoiceStaticContent {
  id: number;
  name: string;
  code: string;
}

export interface AdministrationItem {
  id: number;
  sort: string;
  contractType: string;
  product: string;
  agency: string;
  productNameCMS: string;
  mt: string;
  domesticAccount: string;
  foreignAccount: string;
  icDomestic: string;
  icForeign: string;
  descriptionHR: string;
  descriptionENG: string;
}

export interface SapTaxIndicator {
  id: number;
  code: number;
  taxRate: number;
  noteHR: string;
  noteEN: string;
  noteDE: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  private calculationsUrl =
    'http://localhost:3000/finances_administration_calculations';
  private invoiceStaticContentUrl =
    'http://localhost:3000/finances_invoice_static_content';
  private administrationItemtUrl =
    'http://localhost:3000/finances_administration_item';
  private sapTaxIndicatorsUrl =
    'http://localhost:3000/finances_sap_tax_indicators';

  constructor(private http: HttpClient, private cmsService: CMSService) {}

  //
  //calculations
  getCalculations(page: number, params: any) {
    return this.cmsService.cmsGetFinanceCalculations(page - 1, 50);
  }

  postCalculation(body: FinanceCalculationItem) {
    return this.cmsService.cmsPostFinanceCalculation(body);
  }

  //
  //invoice static content
  getInvoiceStaticContent(params: any): Observable<InvoiceStaticContent[]> {
    return this.http.get<InvoiceStaticContent[]>(this.invoiceStaticContentUrl);
  }
  deleteInvoiceStaticContent(body: number[]) {
    // return this.cmsService;
    //swagger doesn't want to generate this shit
  }
  getInvoiceStaticContentItem(id: number) {
    return this.http.get<InvoiceStaticContent>(
      this.invoiceStaticContentUrl + '/' + id
    );
  }
  patchInvoiceStaticContentItem(id: number, body: InvoiceStaticContentItem) {
    // return this.http.patch(this.invoiceStaticContentUrl + '/' + id, body);
    return this.cmsService.cmsSaveInvoiceStaticContentItem(id, body);
  }

  //
  //administration items
  getAdministrationItems(params: any): Observable<AdministrationItem[]> {
    return this.http.get<AdministrationItem[]>(this.administrationItemtUrl);
  }
  getAdministrationItem(id: number) {
    return this.http.get<AdministrationItem>(
      this.administrationItemtUrl + '/' + id
    );
  }
  patchAdministrationItem(id: number, body: AdministrationItem) {
    return this.http.patch(this.administrationItemtUrl + '/' + id, body);
  }

  //
  //sap tax indicators
  getSapTaxIndicators(params: any): Observable<SapTaxIndicator[]> {
    return this.http.get<SapTaxIndicator[]>(this.sapTaxIndicatorsUrl);
  }
  getSapTaxIndicator(id: number) {
    return this.http.get<SapTaxIndicator>(this.sapTaxIndicatorsUrl + '/' + id);
  }
  patchSapTaxIndicator(id: number, body: SapTaxIndicator) {
    return this.http.patch(this.sapTaxIndicatorsUrl + '/' + id, body);
  }

  //card payment suspenssion
  getB2CSuspensionStatus() {
    return this.cmsService.cmsSuspendB2CStatus('response');
  }
  suspendB2C() {
    return this.cmsService.cmsSuspendB2C();
  }
  resumeB2C() {
    return this.cmsService.cmsResumeB2C();
  }
}
