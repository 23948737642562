import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  public getFirstOfMonth() {
    const d = this.calculateLastOfMonth();
    const firstDateOfMonth = `${d.year}-${d.month}-01`;
    return firstDateOfMonth;
  }
  public getLastOfMonth() {
    const d = this.calculateLastOfMonth();
    const lastDateOfMonth = `${d.year}-${d.month}-${d.lastDay}`;
    return lastDateOfMonth;
  }
  public getToday() {
    const d = new Date();
    let day = d.getDate().toString() as unknown as string;
    let month = (d.getMonth() + 1).toString() as unknown as string;
    let year = d.getFullYear().toString() as unknown as string;
    if (day.length === 1) {
      day = `0${day}`;
    }
    if (month.length === 1) {
      month = `0${month}`;
    }
    const today = `${year}-${month}-${day}`;

    return today;
  }
  private calculateLastOfMonth() {
    const d = new Date();
    const lastOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    let lastDay = lastOfMonth.getDate().toString() as unknown as string;
    let month = (lastOfMonth.getMonth() + 1).toString() as unknown as string;
    let year = lastOfMonth.getFullYear().toString() as unknown as string;

    if (lastDay.length === 1) {
      lastDay = `0${lastDay}`;
    }
    if (month.length === 1) {
      month = `0${month}`;
    }

    return { year: year, month: month, lastDay: lastDay };
  }
}
