import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  CmsEditingUser,
  CMSService,
  CMSUsersService,
  CMSCompaniesService,
  CmsEditingCompany,
} from 'piwe-front-swagger-client';
import { map } from 'rxjs/operators';
import { AgencySelectionService } from '../../../desk-administration/agencies/agency-selection/agency-selection.service';
import {
  IAgency,
  ICompanyDownloadRights,
  Serialization,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  constructor(
    private cmsService: CMSService,
    private cmsCompaniesService: CMSCompaniesService,
    private agencySelectionService: AgencySelectionService,
    private userService: CMSUsersService
  ) {}

  getCompanyData(id: number | undefined): Observable<CmsEditingCompany> {
    return this.cmsCompaniesService.cmsGetCompany(id as number);
  }

  patchCompanyData(id: number | undefined, data: CmsEditingCompany) {
    return this.cmsCompaniesService.cmsUpdateCompany(id as number, data);
  }

  createCompanyData(data: CmsEditingCompany) {
    return this.cmsCompaniesService.cmsCreateCompany(data);
  }

  getUsers(page: number, queryParams: any, companyId: number) {
    let firstName: string | undefined;
    let lastName: string | undefined;
    let email: string | undefined;
    let userId: string | undefined;
    let userRights: string | undefined;
    let accountStatus: string | undefined;

    if (
      queryParams['first_name'] != undefined &&
      queryParams['first_name'].length != 0
    ) {
      firstName = queryParams['first_name'];
    }
    if (
      queryParams['last_name'] != undefined &&
      queryParams['last_name'].length != 0
    ) {
      lastName = queryParams['last_name'];
    }
    if (queryParams['email'] != undefined && queryParams['email'].length != 0) {
      email = queryParams['email'];
    }
    if (queryParams['id'] != undefined && queryParams['id'].length != 0) {
      userId = queryParams['id'];
    }
    if (
      queryParams['download_rights'] != undefined &&
      queryParams['download_rights'].length != 0
    ) {
      userRights = queryParams['download_rights'];
    }
    if (
      queryParams['account_status'] != undefined &&
      queryParams['account_status'].length != 0
    ) {
      accountStatus = queryParams['account_status'];
    }

    return this.cmsService
      .cmsGetCompanyUsers(
        companyId,
        page - 1,
        50,
        firstName,
        lastName,
        email,
        userId,
        userRights,
        accountStatus
      )
      .pipe(
        map((c) => {
          return c.users?.map((c) => Serialization.serializeUser(c));
        })
      );
  }

  getUserData(id: number | string): Observable<CmsEditingUser> {
    return this.userService.cmsGetUser2(id as number);
  }

  patchUserData(id: number | string, data: CmsEditingUser) {
    return this.userService.cmsPutUser2(id as number, data);
  }

  postNewUser(data: CmsEditingUser): Observable<CmsEditingUser> {
    return this.userService.cmsPostUser2(data);
  }

  deleteUser(id: number) {
    return this.userService.cmsDeleteUser2(id);
  }

  getAgencies(): Observable<IAgency[]> {
    return this.agencySelectionService.getAgencyListSub();
  }

  getDownloadRightsUsers(
    companyId: number | string
  ): Observable<ICompanyDownloadRights[]> {
    return of();
  }
}
